import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import emailFill from '@iconify/icons-eva/email-fill';
import phoneCall from '@iconify/icons-eva/phone-call-fill';
import pinFill from '@iconify/icons-eva/pin-fill';

import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Chip } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = {
  headline: 'Contacto',
  children: [
    { id: '1', name: '+56 9 7311 8316', icon: phoneCall, href: 'tel:+56973118316' },
    { id: '2', name: 'contacto@nutrisamu.cl', icon: emailFill, href: 'mailto:contacto@nutrisamu.cl' },
    {
      id: '3',
      name: 'Isla de pascua 1036, San Antonio',
      icon: pinFill,
      href: 'https://maps.google.com/?q=Isla de pascua 1036, San Antonio'
    }
  ]
};

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              <Stack key={LINKS.headline} spacing={2}>
                <Typography component="p" variant="overline">
                  {LINKS.headline}
                </Typography>

                {LINKS.children.map((e) => (
                  // eslint-disable-next-line react/jsx-key
                  <Stack direction="row">
                    <IconStyle icon={e.icon} />
                    <Typography variant="body2" key={e.id}>
                      <a href={e.href} rel="noreferrer" target="_blank">
                        {e.name}
                      </a>
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © {new Date().getFullYear()}. Todos los derechos reservados
        </Typography>
      </Container>
    </RootStyle>
  );
}
